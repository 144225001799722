import React, { useState } from 'react';
import Header from './Header';
import s from "./Layout.module.css"
import Sidebar from './Sidebar';
import MapBox from "../MapBox/MapBox"

export default function Layout() {
    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeComponent, setActiveComponent] = useState('Devices');


    return (
        <div className={s.main}>
            <Header></Header>
            <div className={s.container}>
                {activeSideBar ? (
                    <Sidebar setActiveSideBar={setActiveSideBar} setActiveComponent={setActiveComponent}/>
                ) : (
                    <div onClick={() => setActiveSideBar(true)} className={s.showSidebarButton}>
                        <img src={'/showlogo.svg'}></img>
                    </div>
                )}
                <div className={s.content}>
                    <div className={s.map}>
                        <MapBox />
                    </div>
                </div>
            </div>

        </div>
    )
}