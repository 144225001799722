import React, { useState, useEffect } from 'react';

import Header from './Header';
import s from "./Layout.module.css"
import st from "./LayoutReports.module.css"
import Sidebar from './Sidebar';

import Reports from '../Reports/Reports';
import LogsReport from './LogsReport';




export default function LayoutReports() {
    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeMenuItem, setActiveMenuItem] = useState('item3')
    const [activeComponent, setActiveComponent] = useState('Reports');

    const handleLogsClick = () => {
        setActiveComponent('Logs');
    };

    const handleBackClick = () => {
        setActiveComponent('Reports');
    };

    return (
        <div className={s.main}> 
            <Header></Header>
            <div className={s.container}>
                {activeSideBar ? (
                    <Sidebar setActiveSideBar={setActiveSideBar} setActiveMenuItem={setActiveMenuItem} setActiveComponent={setActiveComponent} />
                ) : (
                    <div onClick={() => setActiveSideBar(true)} className={s.showSidebarButton}>
                        <img src={'/showlogo.svg'}></img>
                    </div>
                )}
                <div className={s.content}>
                {activeComponent === 'Reports' ? (
                    <Reports handleLogsClick={handleLogsClick}/>
                ) : activeComponent === 'Logs' ? (
                    <LogsReport handleBackClick={handleBackClick} />
                ) : (null)}
                </div>
            </div>

        </div>
    )
}