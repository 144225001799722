import React, { useState, useEffect } from 'react';

import Header from './Header';
import s from "./Layout.module.css"
import Sidebar from './Sidebar';
import Settings from '../Settings/Settings';




export default function LayoutSettings() {
    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeMenuItem, setActiveMenuItem] = useState('item4')
    const [activeComponent, setActiveComponent] = useState('Settings');

    return (
        <div className={s.main}>
            <Header></Header>
            <div className={s.container}>
                {activeSideBar ? (
                    <Sidebar setActiveSideBar={setActiveSideBar} setActiveMenuItem={setActiveMenuItem} setActiveComponent={setActiveComponent} />
                ) : (
                    <div onClick={() => setActiveSideBar(true)} className={s.showSidebarButton}>
                        <img src={'/showlogo.svg'}></img>
                    </div>
                )}
                <div className={s.content}>
                    <Settings />
                </div>
            </div>

        </div>
    )
}