import React, { useState, useEffect, useContext, useRef } from 'react';
import s from "./Devices.module.css"
import { UserContext } from "../../App";
import TreeDiv from '../AddressTree/AddressTree';
import { exportDevicesToExcel } from '../../store/exportToExcel';
import { Link, useLocation } from 'react-router-dom';



function formatDate(lastDateTime) {
    if (lastDateTime === 0) {
        return "Нет данных";
    } else {
        return new Date(lastDateTime * 1000).toLocaleDateString('ru-RU');
    }
}

export default function Devices({ onButtonClick, handleLogsClick }) {

    const [searchQuery, setSearchQuery] = useState('');
    const [searchAddressQuery, setSearchAddressQuery] = useState('');
    const { user, setUser } = useContext(UserContext);
    const [devices, setDevice] = useState([])
    const [filteredDevices, setFilteredDevices] = useState([]);
    const [fullAddress, setFullAddress] = useState([]);
    const [openTree, setOpenTree] = useState(false)
    const popupRef = useRef(null);
    const buttonRef = useRef(null);
    const dataToExport = filteredDevices.length > 0 ? filteredDevices : devices;

    useEffect(() => {
        async function fetchDevice() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/device/device-list`);
                user.cs_group.forEach(csGroup => {
                    url.searchParams.append('cs_group_id', csGroup._id);
                });
                user.nbiot_group.forEach(nbiotGroup => {
                    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
                });

                let response = await fetch(url);
                const data = await response.json();

                setDevice(data);
                setFilteredDevices(data);
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchDevice();
    }, []);


    const recursiveSearch = (obj, searchTerm) => {
        if (typeof obj === 'string' || typeof obj === 'number') {
            return obj.toString().toLowerCase().includes(searchTerm.toLowerCase());
        }

        if (Array.isArray(obj)) {
            return obj.some(element => recursiveSearch(element, searchTerm));
        }

        if (obj && typeof obj === 'object') {
            return Object.values(obj).some(value => recursiveSearch(value, searchTerm));
        }

        return false;
    };

    useEffect(() => {
        const filtered = devices.filter(device =>
            recursiveSearch(device, searchQuery)
        );
        setFilteredDevices(filtered);
    }, [searchQuery]);


    const handleFullAddressChange = (address) => {
        setFullAddress(address)
    };

    const handleItemClick = (event) => {
        event.stopPropagation();
        setOpenTree(prevState => !prevState);
    };

    const handleExportClick = async (event) => {
        try {
            await exportDevicesToExcel({ dataToExport });
        } catch (error) {
            console.error('Error exporting devices:', error);
        }
    };

    const handleClickOutside = (event) => {
        if (
            popupRef.current && !popupRef.current.contains(event.target) &&
            buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            setOpenTree(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div>
            <div className={s.frame12}>
                <input
                    type='text'
                    className={s.search}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder='Поиск по imei, devEUI или номеру'
                >
                </input>
                <img src={'/search.svg'}></img>
            </div>
            <div className={s.frame28}>
                <div className={s.frame16} onClick={onButtonClick}>
                    <div className={s.frame16Text}>Импорт устройств</div>
                </div>
                <div className={s.frame13} onClick={handleExportClick}>
                    <div className={s.frame13Text}>Экспорт файла</div>
                </div>
                <div className={s.frame14} onClick={handleLogsClick}>
                    <img src={'/log.svg'} alt="" />
                    <div className={s.frame14Text}>Лог</div>
                </div>
                <div className={s.frame15}>
                    <img src={'/add.svg'} alt="" />
                    <div className={s.frame15Text}>Добавить</div>
                </div>

            </div>
            <div className={s.frame62} onClick={handleItemClick} ref={buttonRef}>
                <div className={s.frame75} >
                    <div className={s.frame75logo}>
                        <img src={'/home.svg'}></img>
                    </div>
                    <div className={s.frame75Text}>Список адресов</div>
                    <div className={s.arrowDown}>
                        <img src={'/arrowback.svg'}></img>

                    </div>
                </div>
            </div>
            {openTree && (
                <div className={s.addressPopup} ref={popupRef}>
                    <div className={s.address}>
                        <div className={s.addressInput}>
                            <input
                                type='text'
                                className={s.addressSearch}
                                value={searchAddressQuery}
                                onChange={(e) => setSearchAddressQuery(e.target.value)}
                                placeholder='Поиск адреса'
                            >
                            </input>
                            <img src={'/search.svg'}></img>
                        </div>
                        <div>
                            <TreeDiv setSelectedAddress={(address) => {
                                setSelectedAddress(address);
                            }} handleFullAddressChange={handleFullAddressChange} />
                        </div>
                    </div>
                </div>
            )}
            <Table devices={filteredDevices} fullAddress={fullAddress} />
        </div>
    )
}


const Table = ({ devices, fullAddress }) => {
    const headers = ['Статус', 'Тип', 'Номер счетчика', 'DevEUI/Imei', 'Адрес', 'Посл. показания', 'Дата']
    const { address } = location.state || {};
    const [filteredDevices, setFilteredDevices] = useState([]);
    const [sortConfig, setSortConfig] = useState(null);

    const headerKeys = {
        'Статус': 'data.0.status',
        'Тип': 'device.0.resourse_type',
        'Номер счетчика': 'device.0.dev_number',
        'DevEUI/Imei': 'device_iot.deveui',
        'Адрес': 'address',
        'Посл. показания': 'data.0.last_volume',
        'Дата': 'data.0.last_date_time',
        'Владелец': 'paid_info.0.ownership',
        'Форма собственности': 'paid_info.0.type_ownership',
        'Описание устройства': 'device.0.info',
        'Дата окончания поверки': 'device.0.verification_end_date'
    };

    const sortData = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sortedData = [...filteredDevices].sort((a, b) => {
            const keys = key.split('.');
            let aValue = a;
            let bValue = b;
            keys.forEach(k => {
                aValue = aValue[k];
                bValue = bValue[k];
            });

            if (key === 'data.0.last_volume') {
                aValue = a.data[0].last_volume + a.data[0].start_volume;
                bValue = b.data[0].last_volume + b.data[0].start_volume;
            }

            if (key === 'address') {
                aValue = `${aValue[0].country} ${aValue[0].region} ${aValue[0].city} ${aValue[0].street} ${aValue[0].building} ${a.address_number}`;
                console.log(aValue);
                bValue = `${bValue[0].country} ${bValue[0].region} ${bValue[0].city} ${bValue[0].street} ${bValue[0].building} ${b.address_number}`;
            }

            if (aValue < bValue) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setFilteredDevices(sortedData);
        setSortConfig({ key, direction });
    };

    const displayedDevices = filteredDevices.length > 0 ? filteredDevices : devices;

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = displayedDevices.slice(indexOfFirstItem, indexOfLastItem);

    const renderItems = currentItems.map(device => (
        <tr key={device.id}>
            <td>
                <div className={s.warp}>
                    <div className={s.statusElipse}>
                        <img src={`/${device.data[0].status.toLowerCase()}.svg`} alt="" />
                    </div>
                    <div className={s.lock}>
                        <img src={'/lock.svg'} />
                    </div>
                </div>
            </td>
            {device.device[0].dev_type == "ВС" && device.device[0].resourse_type == "Горячая вода" ? <td className={s.redText}>ГВС</td>
                : device.device[0].dev_type == "ВС" && device.device[0].resourse_type == "Холодная вода" ? <td className={s.blueText}>ХВС</td>
                : device.device[0].dev_type == "ТС" ? <td>ТС</td>
                    : <td className={s.blueText}>ВС</td>}
            <td className={s.tableListText}>
                {device.device[0].dev_number}
            </td>
            <td className={s.tableListText}>
                {device.device_iot?.deveui || device.device_iot?.serialnumber || ""}
            </td>
            <td className={s.tableListText}>
                {`${device.address[0].country ? device.address[0].country + ', ' : ''}` +
                    `${device.address[0].region ? device.address[0].region + ', ' : ''}` +
                    `${device.address[0].city ? device.address[0].city + ', ' : ''}` +
                    `${device.address[0].street ? device.address[0].street + ', ' : ''}` +
                    `${device.address[0].building ? device.address[0].building + (device.address_number !== null ? ', ' : '') : ''}` +
                    `${device.address_number !== null && device.address_number !== undefined ? device.address_number : ''}`}
            </td>
            <td className={s.tableListText}>
                {`${(parseFloat(device.data[0].last_volume + device.data[0].start_volume) * (device.device[0].factor)).toFixed(3)} 
                ${device.device[0].dev_type === 'ВС' ? 'м³' 
                : device.device[0].dev_type === 'ТС' ? 'ГДж'
                : 'кВт-Ч'}`
                }
            </td>
            <td className={s.tableListText}>
                {formatDate(device.data[0].last_date_time)}
            </td>
            <td>
                <Link to={`/device/${device._id}&${device.device[0].dev_number}`} id={`${device.device[0].dev_number}`}>
                    <img src={'/document.svg'} />
                </Link>
            </td>
        </tr>
    )

    );

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(displayedDevices.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const totalPages = Math.ceil(displayedDevices.length / itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleItemsPerPageChange = (event) => {
        setItemPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    useEffect(() => {
        if (address != undefined) {
            fullAddress = address.split(',')
        }

        let filtered = devices.slice();

        if (fullAddress !== null && fullAddress.length > 0) {

            filtered = filtered.filter(item => {
                let itemAddress = `${item.address[0].country}, ${item.address[0].region}, ${item.address[0].city}, ${item.address[0].street}, ${item.address[0].building}, ${item.device_iot.deveui}`.toLowerCase();
                return fullAddress.every(addr => itemAddress.includes(addr.toLowerCase()));
            });
        }

        setFilteredDevices(filtered);

    }, [fullAddress, address, devices]);


    return (
        <div className={s.tableContainer}>
            <table className={s.deviceTable}>
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th key={header} onClick={() => headerKeys[header] && sortData(headerKeys[header])}>
                                <div className={s.warp}>
                                    <div className={s.frame48Text}>{header}</div>
                                    <div className={s.tableArrowDown}>
                                        {sortConfig && sortConfig.key === headerKeys[header] && (
                                            sortConfig.direction === 'ascending'
                                        )}
                                        <img src={'/tablearrowdown.svg'}></img>
                                    </div>
                                </div>
                            </th>
                        ))}
                        <th>
                            <div className={s.setting}>
                                <img src={'/setting.svg'}></img>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>{renderItems}</tbody>
            </table>
            <div className={s.paginate}>
                <div className={s.quantityDevice}>
                    {displayedDevices.length} всего
                </div>
                <div className={s.devicePerPage}>
                    <div className={s.devicePerPageText}>Показать</div>
                    <select className={s.inputPerPage} value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className={s.currentPage}>
                    <div className={s.arrowLeft} onClick={handlePreviousPage}>
                        <img src={'/arrowleft.svg'}></img>
                    </div>
                    <div className={s.page}> {currentPage}</div>
                    <div className={s.arrowRight} onClick={handleNextPage}>
                        <img src={'/arrowright.svg'}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}


