import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Header from './Header';
import s from "./Layout.module.css"
import Sidebar from './Sidebar';
import Devices from './Devices';
import DeviceImportData from '../DeviceImportData/DeviceImportData';
import DeviceInfo from './DeviceInfo';
import LogsDevice from './LogsDevice';
import LogsInfoDevice from './LogsInfoDevice';


export default function LayoutDevices() {
    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeMenuItem, setActiveMenuItem] = useState('item2')
    const [activeComponent, setActiveComponent] = useState('Devices');

    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        if (location.pathname.startsWith('/device/')) {
            setActiveComponent('DeviceInfo');
        } else if (location.pathname === '/devices') {
            setActiveComponent('Devices');
        }
    }, [location]);

    const handleButtonClick = () => {
        setActiveComponent('DeviceImportData');
    };

    const handleLogsClick = () => {
        setActiveComponent('Logs');
    };

    const handleButtonClickInfo = () => {
        setActiveComponent('DeviceInfo');
    };

    const handleBackClick = () => {
        setActiveComponent('Devices');
    };

    const handleInfoLogsClick = () => {
        setActiveComponent('LogsInfoDevice');
    };

    return (
        <div className={s.main}>
            <Header></Header>
            <div className={s.container}>
                {activeSideBar ? (
                    <Sidebar setActiveSideBar={setActiveSideBar} setActiveMenuItem={setActiveMenuItem} setActiveComponent={setActiveComponent} />
                ) : (
                    <div onClick={() => setActiveSideBar(true)} className={s.showSidebarButton}>
                        <img src={'/showlogo.svg'}></img>
                    </div>
                )}
                <div className={s.content}>
                    {activeComponent === 'Devices' ? (
                        <Devices onButtonClick={handleButtonClick} handleLogsClick={handleLogsClick} deviceInfo={handleButtonClickInfo} />
                    ) : activeComponent === 'DeviceImportData' ? (
                        <DeviceImportData onBackClick={handleBackClick} />
                    ) : activeComponent === 'DeviceInfo' ? (
                        <DeviceInfo deviceId={params.id} onBackClick={handleBackClick} handleInfoLogsClick={handleInfoLogsClick}/>
                    ) : activeComponent === 'Logs' ? (
                        <LogsDevice onBackClick={handleBackClick}/>
                    ) : activeComponent === 'LogsInfoDevice' ? (
                        <LogsInfoDevice deviceId={params.id} onBackClick={handleButtonClickInfo}/>
                    ) : (null)}
                </div>
            </div>

        </div>
    )
}