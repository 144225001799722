import s from "./DevicesImport.module.css"
import { Formik, Field, Form, useFormikContext } from 'formik';
import scanner from './scanner.png'
import example from './example.csv'
import examplenbiot from './examplenbiot.csv'
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from "../../App";
import QRModal from "./QRModal";
import { useFormik } from 'formik';
import { TextField, Select, FormControl, InputLabel, MenuItem, Button, Typography, Autocomplete } from '@mui/material';



const useHideMessage = (submitStatus, setSubmitStatus, setErrorMessage) => {
    useEffect(() => {
        if (submitStatus === 'success' || submitStatus === 'error') {
            const timer = setTimeout(() => {
                setSubmitStatus(null);
                setErrorMessage(null);
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [submitStatus, setSubmitStatus, setErrorMessage]);
};

export const DeviceForm = () => {
    

    const { user, setUser } = useContext(UserContext);
    const [scanActive, setScanActive] = useState(false);
    const [activeField, setActiveField] = useState(null);
    const [qr, setQR] = useState(false)


    const closeModal = () => {
        setScanActive(false)
    };

    const formik = useFormik({
        initialValues: {
            address: '',
            number: '',
            deveui: '',
            appeui: '',
            appkey: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/chirpstack/create_tenant`, {
                    method: 'POST',
                    headers: {
                        'X-Username': user.username,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values)
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json(); // Декодируем JSON из ответа
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleButtonClick = (field) => {
        setActiveField(field);
        setQR(true)
        setScanActive(true);
    };

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                margin="normal"
                id="address"
                name="address"
                label="Адрес"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                margin="normal"
                id="number"
                name="number"
                label="Номер квартиры"
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <div className={s.inputQr}>
                <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    margin="normal"
                    id="deveui"
                    name="deveui"
                    label="DevEUI"
                    value={formik.values.deveui}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >

                </TextField>
                <div className={s.qrDiv}>
                    <img onClick={() => handleButtonClick('deveui')} className={s.img} src={scanner}></img>
                </div>
            </div>
            <div className={s.inputQr}>
                <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    margin="normal"
                    id="appeui"
                    name="appeui"
                    label="AppEUI"
                    value={formik.values.appeui}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                </TextField>
                <div className={s.qrDiv}>
                    <img onClick={() => handleButtonClick('appeui')} className={s.img} src={scanner}></img>
                </div>
            </div>
            <div className={s.inputQr}>
                <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    margin="normal"
                    id="appkey"
                    name="appkey"
                    label="AppKey"
                    value={formik.values.appkey}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                </TextField>
                <div className={s.qrDiv}>
                    <img onClick={() => handleButtonClick('appkey')} className={s.img} src={scanner}></img>
                </div>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
            {scanActive && (
                <QRModal isOpen={!!qr} closeModal={closeModal} setFieldValue={formik.setFieldValue} setScanActive={setScanActive} activeField={activeField} />
            )}
        </form>
    );
}


export const DevicesImportForm = () => {
    

    const { user, setUser } = useContext(UserContext);

    const formik = useFormik({
        initialValues: {
            application: '',
            file: null,
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const formData = new FormData();
            if (values.file) {
                formData.append('file', values.file);
            }
            formData.append('application', values.cs_group);

            console.log(values);

            console.log(formData)
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/device/create-device`);
                url.searchParams.append('user', user.email);
                let response = await fetch(url, {
                    method: 'POST',
                    body: formData
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                console.log(data);
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })


    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств Lora</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="cs_group"
                    name="cs_group"
                    label="Группа устройств Lora"
                    value={formik.values.cs_group}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    {user.cs_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={s.inputField}>
                <input
                    accept=".csv"
                    id="file"
                    name="file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                    }}
                />
                <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                        Выберите файл
                    </Button>
                    {formik.values.file && (
                        <span className={s.fileName}>{formik.values.file.name}</span>
                    )}
                </label>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}


export const DevicesEditForm = () => {
    

    const { user, setUser } = useContext(UserContext);

    const formik = useFormik({
        initialValues: {
            file: null,
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const formData = new FormData();
            if (values.file) {
                formData.append('file', values.file);
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/device/edit-devices`, {
                    method: 'POST',
                    headers: {
                        'X-Username': user.username,
                    },
                    body: formData
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                console.log(data);
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })


    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <div className={s.inputField}>
                <input
                    accept=".csv"
                    id="file"
                    name="file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                    }}
                />
                <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                        Выберите файл
                    </Button>
                    {formik.values.file && (
                        <span className={s.fileName}>{formik.values.file.name}</span>
                    )}
                </label>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}


export const DevicesNbiotImportForm = () => {
    

    const { user, setUser } = useContext(UserContext);

    const formik = useFormik({
        initialValues: {
            application: '',
            file: null,
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const formData = new FormData();
            if (values.file) {
                formData.append('file', values.file);
            }
            formData.append('application', values.application);

            console.log(formData)
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/device/create-device-nbiot`, {
                    method: 'POST',
                    headers: {
                        'X-Username': user.username,
                    },
                    body: formData
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                console.log(data);
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств NBIoT</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="application"
                    name="application"
                    label="Группа устройств NBIoT"
                    value={formik.values.application}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    {user.nbiot_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={s.inputField}>
                <input
                    accept=".csv"
                    id="file"
                    name="file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                    }}
                />
                <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                        Выберите файл
                    </Button>
                    {formik.values.file && (
                        <span className={s.fileName}>{formik.values.file.name}</span>
                    )}
                </label>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>

    );
}

export const DevicesNbiotEditForm = () => {
    

    const { user, setUser } = useContext(UserContext);

    const formik = useFormik({
        initialValues: {
            file: null,
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const formData = new FormData();
            if (values.file) {
                formData.append('file', values.file);
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/device/edit-device-nbiot`, {
                    method: 'POST',
                    headers: {
                        'X-Username': user.username,
                    },
                    body: formData
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                console.log(data);
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <div className={s.inputField}>
                <input
                    accept=".csv"
                    id="file"
                    name="file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                    }}
                />
                <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                        Выберите файл
                    </Button>
                    {formik.values.file && (
                        <span className={s.fileName}>{formik.values.file.name}</span>
                    )}
                </label>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}